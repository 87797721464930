// Generated by Framer (b742ddc)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Typewriter from "https://framerusercontent.com/modules/vgSbxmWWvbgW6ShllXld/9oZlwlOxsp6zJVFpVkIp/Typewriter.js";
import * as localizedValues from "./hDjQcoIVp-0.js";
const TypewriterFonts = getFonts(Typewriter);

const cycleOrder = ["wJZBkwSRd"];

const serializationHash = "framer-WR5pS"

const variantClassNames = {wJZBkwSRd: "framer-v-84z64t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {A55YVbfVC: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "wJZBkwSRd", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearkht5ai = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 8500)
})

useOnVariantChange(baseVariant, {default: onAppearkht5ai})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-84z64t", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"wJZBkwSRd"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-tdab44-container"} layoutDependency={layoutDependency} layoutId={"xTzJdE2tA-container"}><Typewriter autoStart caretVisibility color={"var(--token-31258a9d-1bcb-4652-b98f-3c6e8e846a1d, rgb(17, 17, 17))"} cursor={"|"} cursorColor={"rgb(153, 153, 153)"} delayNumber={0.15} delayType={false} font={{fontFamily: "Inter", fontSize: 16, fontWeight: 400, letterSpacing: -0.6, lineHeight: 1.2, lineHeightPixels: 100, lineHeightType: true, offset: 0, textAlign: "left", whiteSpace: "normal"}} height={"100%"} id={"xTzJdE2tA"} layoutId={"xTzJdE2tA"} loop pauseFor={1} split={false} style={{width: "100%"}} tag={"paragraph"} text={"Discover modern living in this beautifully appointed apartment located in the vibrant city of Oostende. With a generous habitable area of 124m², this residence offers ample space and comfort, perfect for individuals or families looking for a cozy yet contemporary home.  Featuring two spacious bedrooms, this apartment is designed for relaxation and privacy. The well-lit and generously sized rooms provide a serene retreat at the end of the day. The single bathroom is elegantly finished, encompassing modern fixtures that ensure a luxurious daily experience."} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WR5pS.framer-wemz74, .framer-WR5pS .framer-wemz74 { display: block; }", ".framer-WR5pS.framer-84z64t { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 436px; }", ".framer-WR5pS .framer-tdab44-container { flex: 1 0 0px; height: auto; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WR5pS.framer-84z64t { gap: 0px; } .framer-WR5pS.framer-84z64t > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-WR5pS.framer-84z64t > :first-child { margin-left: 0px; } .framer-WR5pS.framer-84z64t > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 192
 * @framerIntrinsicWidth 436
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhDjQcoIVp: React.ComponentType<Props> = withCSS(Component, css, "framer-WR5pS") as typeof Component;
export default FramerhDjQcoIVp;

FramerhDjQcoIVp.displayName = "Variant 1";

FramerhDjQcoIVp.defaultProps = {height: 192, width: 436};

addFonts(FramerhDjQcoIVp, [{explicitInter: true, fonts: []}, ...TypewriterFonts], {supportsExplicitInterCodegen: true})